import { useAuth } from "@/auth/hooks/useAuth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { cn } from "@/libs/utils";
import {
  Award,
  Bell,
  FolderIcon,
  LayoutDashboard,
  MessagesSquare,
  PanelLeftClose,
  PanelLeftOpen,
  Power,
  ScrollText,
  Settings,
} from "lucide-react";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import TreeView from "./TreeView";

type LeftbarProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};
const Leftbar: FC<LeftbarProps> = ({ setIsOpen, isOpen }) => {
  const { isAuthenticated, logout } = useAuth();

  return (
    <div
      className={`sticky top-0 flex  min-h-screen flex-col py-8 ${
        isOpen ? "pl-8 pr-4" : "flex flex-col items-center px-2"
      }  bg-[#f4f4f4] bg-cover bg-center`}
    >
      <Link to="/dashboard">
        {isOpen ? (
          <img src="/logo.svg" alt="logo verdeo" width={90} className="mb-7" />
        ) : (
          <img
            src="/logo-min.svg"
            alt="logo verdeo"
            width={20}
            className="mb-7"
          />
        )}
      </Link>

      <ul>
        <LinkNav isOpen={isOpen} picto={<LayoutDashboard size={18} />} link="/">
          Dashboard
        </LinkNav>
      </ul>

      {isOpen ? (
        <TreeView />
      ) : (
        <button className="mb-4" onClick={() => setIsOpen(true)}>
          <FolderIcon className="flex size-5 opacity-70" />{" "}
        </button>
      )}
      <ul>
        <LinkNav isOpen={isOpen} picto={<ScrollText size={18} />} link="/">
          Mes factures
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<Award size={18} />} link="/">
          Mes certificats
        </LinkNav>
        {/* <LinkNav isOpen={isOpen} picto={<User2 size={18} />} link="/">
          Profil
        </LinkNav> */}
        <LinkNav isOpen={isOpen} picto={<Bell size={18} />} link="/">
          Notification
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<MessagesSquare size={18} />} link="/">
          Messages
        </LinkNav>
      </ul>
      <ul className="mt-auto">
        <LinkNav
          isOpen={isOpen}
          picto={<Settings size={20} />}
          link="/"
          className="mb-0"
        >
          Paramètres
        </LinkNav>
        <div
          className={`mt-auto flex flex-wrap ${
            isOpen ? "justify-between" : "justify-center"
          } items-center transition-all`}
        >
          {!isAuthenticated ? (
            <Link
              to="/login"
              className="rounded-[4rem] border-2 border-foreground bg-background px-4 py-2 text-[#878787] transition-all hover:bg-foreground hover:text-background"
            >
              Connexion
            </Link>
          ) : (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <div className="flex cursor-pointer items-center gap-2 text-[#878787] transition-all hover:text-destructive">
                  <Power size={18} className="text-[#878787]" />
                  {isOpen && "Deconnexion"}
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Êtes-vous sûr de vouloir vous déconnecter ?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annuler</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => logout(window.location.href)}
                  >
                    Se déconnecter
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}

          <button
            className={`fixed bottom-6 flex size-9 items-center justify-center rounded-md bg-gray-100 text-foreground ${
              !isOpen ? "left-[50px]" : "left-[238px]"
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <PanelLeftClose size={18} className="text-[#878787]" />
            ) : (
              <PanelLeftOpen size={18} className="text-[#878787]" />
            )}
          </button>
        </div>
      </ul>
    </div>
  );
};

const LinkNav = ({
  isOpen,
  link,
  picto,
  children,
  className,
}: {
  isOpen: boolean;
  link: string;
  picto: ReactNode;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <li className={cn("mb-4", className)}>
      <Link
        to={`/dashboard${link}`}
        className={`line-clamp-1 flex flex-wrap items-center gap-2 text-left text-[13px] leading-4 text-[#878787]  hover:text-[#1C5114] ${
          !isOpen ? "mb-3  justify-center" : "mb-3"
        }`}
        title={children?.toString()}
      >
        {picto} {isOpen && children}
      </Link>
    </li>
  );
};
export default Leftbar;
