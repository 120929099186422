import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useProjectStore } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProjectType } from "@/types";
import { lazy, Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";

const ReportingDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/ReportingDetail").then(
    (module) => ({ default: module.default })
  )
);
const DescriptionDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/DescriptionDetail").then(
    (module) => ({ default: module.default })
  )
);
const PerformanceDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/PerformanceDetail").then(
    (module) => ({ default: module.default })
  )
);
const TerrainDetail = lazy(() =>
  import("@/dashboard/components/project/tabs/TerrainDetail").then(
    (module) => ({ default: module.default })
  )
);

// Tab configuration
const tabsConfig = [
  {
    value: "descriptif",
    label: "Descriptif du projet",
    component: DescriptionDetail,
  },
  { value: "terrain", label: "Terrain rattaché", component: TerrainDetail },
  {
    value: "perf",
    label: "Suivi de performance ESG",
    component: PerformanceDetail,
  },
  { value: "reporting", label: "Reporting", component: ReportingDetail },
];

const DashboardProject = () => {
  const { id } = useParams<{ id: string }>();
  const { project, setProject } = useProjectStore();
  const { data, loading, error } = useFetchDataV2<ProjectType>(
    `${import.meta.env.VITE_API_ENDPOINT}/project/${id}`,
    id
  );
  useEffect(() => {
    if (data && !loading) {
      setProject(data); // Mettre à jour le store avec le nouveau projet
    }
  }, [data, loading, setProject]);

  if (loading) return <LoadingSpinner />;
  if (error)
    return <p className="text-red-500">Une erreur est survenue : {error}</p>;

  if (!project) return <p className="text-gray-500">Aucun projet trouvé.</p>;

  return (
    <div>
      <ReactBreadcrumb
        items={[
          { href: "/dashboard/programs", name: "Mes programmes" },
          {
            href: `/dashboard/program/${project.program?.id}`,
            name: project.program?.name || "",
          },
          { href: "", name: project.name || "" },
        ]}
      />
      <h1 className="my-4 text-2xl font-medium">Projet : {project?.name}</h1>
      {project?.description && (
        <p
          className="mb-4 mt-2 text-[#7a7a7a]"
          dangerouslySetInnerHTML={{
            __html: project?.description.replace(/\n/g, "<br />"),
          }}
        />
      )}
      <Tabs defaultValue="descriptif" className="w-full">
        <TabsList className="mb-2 grid w-full grid-cols-4 bg-background">
          {tabsConfig.map((tab, key: number) => (
            <TabsTrigger
              aria-label={tab.label}
              key={tab.value}
              value={tab.value}
              className={`rounded-none border-b-2 text-[#626262] data-[state=active]:border-foreground data-[state=active]:shadow-none ${
                key >= 2 && project.status != 3
                  ? "pointer-events-none cursor-context-menu opacity-20"
                  : ""
              }`}
            >
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>

        {tabsConfig.map(
          (tab, key: number) =>
            key < 2 && (
              <TabsContent key={tab.value} value={tab.value} className="mb-4">
                <Suspense fallback={<LoadingSpinner />}>
                  <tab.component />
                </Suspense>
              </TabsContent>
            )
        )}
      </Tabs>
    </div>
  );
};

export default DashboardProject;
