import { ProjectType } from "@/types";
import { create } from "zustand";

// Store pour gérer le pourcentage
const usePercentageStore = create<{
  percentage: number;
  setPercentage: (value: number) => void;
  resetPercentage: () => void;
}>((set) => ({
  percentage: 0,
  setPercentage: (value) => set({ percentage: value }),
  resetPercentage: () => set({ percentage: 0 }),
}));

type ProjectState = {
  project: ProjectType | null;
  setProject: (project: ProjectType) => void;
};

const useProjectStore = create<ProjectState>((set) => ({
  project: null,
  setProject: (project) => set({ project }),
}));

// Store pour gérer l'état des modals
const useModal = create<{
  modals: { [key: string]: boolean };
  setOpenModal: (modal: { name: string; value: boolean }) => void;
}>((set) => ({
  modals: {},
  setOpenModal: ({ name, value }) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [name]: value,
      },
    })),
}));

// Clé utilisée pour stocker les données dans localStorage
const STORAGE_KEY = "previewPerformance";

const usePreviewPerformance = create<{
  previews: { [key: string]: string };
  setPreviews: (preview: { name: string; value: string }) => void;
}>((set) => ({
  // Initialisation des données depuis localStorage
  previews: JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}"),

  // Mise à jour de l'état et de localStorage
  setPreviews: ({ name, value }) => {
    set((state) => {
      const updatedPreviews = {
        ...state.previews,
        [name]: value,
      };

      // Synchronisation avec localStorage
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPreviews));

      return { previews: updatedPreviews };
    });
  },
}));
export { useModal, usePercentageStore, usePreviewPerformance, useProjectStore };
