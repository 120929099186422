import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import ProjectList from "@/dashboard/components/project/ProjectList";
import { useModal } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { useDatabase } from "@/programs/hooks/useDatabase";
import { ProgramType } from "@/types";
import { FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddProject from "./AddProject";

const DashboardProgram = () => {
  const { setOpenModal } = useModal();
  const { updateProgramInDB } = useDatabase();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    projectsTemp: any,
    projectsTempBdd: any
  ) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setReloadData(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const projectsArray: any[] = [];
    projectsTemp.map((project: any) => {
      if (project.type) {
        projectsArray.push({
          name: project.name,
          type: project.type,
        });
      }
    });
    const projectsArrayBdd: any[] = [];
    projectsTempBdd?.map((project: any) => {
      if (project.type) {
        projectsArrayBdd.push({
          name: project.name,
          type: project.type,
        });
      }
    });

    const program = await updateProgramInDB(
      { projects: [...projectsArrayBdd] },
      Number(id)
    );
    toast({
      title: "Vos informations sont bien prises en compte.",
    });
    setOpenModal({ name: "project", value: false });

    navigate(
      "/dashboard/project/" + program?.projects[program?.projects.length - 1].id
    );
  };
  const { data, loading, error } = useFetchDataV2<ProgramType>(
    `${import.meta.env.VITE_API_ENDPOINT}/program/${id}`
  );

  loading && <LoadingSpinner />;

  error && new Error("Une erreur est survenue: " + error);
  return (
    <div>
      <ReactBreadcrumb
        items={[
          {
            href: "/dashboard/programs",
            name: "Mes programmes",
          },
          {
            href: ``,
            name: data?.name || "...",
          },
        ]}
      />
      <h1 className="mb-5 mt-4 text-2xl font-medium">Mes projets</h1>

      {data && <ProjectList projects={data.projects} />}

      <AddProject onHandleSubmit={handleSubmit} />
    </div>
  );
};

export default DashboardProgram;
