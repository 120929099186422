import { cn } from "@/libs/utils";
import { Info } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

type CategoryProjectPropsType = {
  name: string;
  icon: string;
  type: number;
  link?: string;
  isActive?: boolean;
  className?: string;
  description?: string;
};

const CardTypeProject: FC<CategoryProjectPropsType> = ({
  name,
  icon,
  type,
  link,
  className,
  description,
  isActive,
  ...props
}) => {
  return link ? (
    <Link
      to={link}
      className={cn(
        `relative ${
          isActive ? (type === 1 ? "bg-primary" : "bg-[#FF9500]") : "bg-background"
        } overflow-hidden min-h-[64px] h-full py-3 px-[10px] rounded-[5px] shadow-[0_0_4px_0_rgba(0,0,0,0.25)] flex flex-col gap-2 `,
        className
      )}
    >
      <div className="relative z-20">
        {icon ? (
          <img
            src={icon}
            alt={name}
            width={25}
            height={22}
            className={isActive ? "invert" : "opacity-50"}
          />
        ) : (
          <div className="size-6"></div>
        )}

        <h3
          className={`mt-2 text-sm font-medium ${
            isActive ? "text-background" : "text-[#7F7F7F]"
          }`}
        >
          {name}
        </h3>
      </div>
      <div
        className={`${
          isActive ? "bg-[rgba(0,0,0,.70)]" : ""
        } absolute left-0 top-0 z-10 size-full`}
      ></div>
      <Popover>
        <PopoverTrigger asChild className="absolute right-1 top-1">
          <Info
            className={`${
              isActive ? "text-background" : "text-foreground/50"
            } size-5`}
          />
        </PopoverTrigger>
        <PopoverContent className="text-xs">{description} </PopoverContent>
      </Popover>
    </Link>
  ) : (
    <div
      className={cn(
        `relative overflow-hidden ${
          isActive ? (type == 1 ? "bg-primary" : "bg-[#FF9500]") : "bg-background"
        } min-h-[64px] h-full py-3 px-[10px] rounded-[5px] shadow-[0_0_4px_0_rgba(0,0,0,0.25)] flex flex-col gap-2 `,
        className
      )}
      {...props}
    >
      <div className="relative z-20">
        {icon ? (
          <img
            src={icon}
            alt={name}
            width={25}
            height={22}
            className={isActive ? "invert" : "opacity-50"}
          />
        ) : (
          <div className="size-6"></div>
        )}
        <h3
          className={`mt-2 text-sm font-medium ${
            isActive ? "text-background" : "text-[#7F7F7F]"
          }`}
        >
          {name}
        </h3>
      </div>
      <div
        className={`${
          isActive ? "bg-[rgba(0,0,0,.70)]" : ""
        } absolute left-0 top-0 z-10 size-full`}
      ></div>
      <Popover>
        <PopoverTrigger
          asChild
          className="absolute right-2 top-2 z-50 cursor-pointer"
        >
          <Info
            className={`${
              isActive ? "text-background" : "text-foreground/50"
            } size-5`}
          />
        </PopoverTrigger>
        <PopoverContent className="text-xs">{description} </PopoverContent>
      </Popover>
    </div>
  );
};

export default CardTypeProject;
