import { CheckCircle, CircleX } from "lucide-react";
import { FC } from "react";

const PasswordStrengthCheck: FC<{
  password: string;
  isPasswordValid: boolean;
  className?: string;
}> = ({ password, isPasswordValid, className }) => {
  const checks = [
    { test: password.length >= 8, message: "Au moins 8 caractères" },
    { test: /[a-z]/.test(password), message: "Au moins une lettre minuscule" },
    { test: /[A-Z]/.test(password), message: "Au moins une lettre majuscule" },
    { test: /\d/.test(password), message: "Au moins un chiffre" },
    {
      test: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      message: "Au moins un caractère spécial",
    },
  ];

  return (
    <ul className={className}>
      <li className="mb-1 flex items-center gap-1">
        {isPasswordValid ? (
          <CheckCircle size="15" className="text-green-600" />
        ) : (
          <CircleX size="15" className="text-destructive" />
        )}{" "}
        <span
          className={`font-medium ${isPasswordValid ? "text-green-600" : ""}`}
        >
          Fiabilité du mot de passe :{" "}
        </span>
        {isPasswordValid ? (
          <span className="text-green-600">Fort</span>
        ) : (
          <span className="text-destructive">Faible</span>
        )}{" "}
      </li>
      {checks.map((check, index) => (
        <li key={index} className="mb-1 flex items-center gap-1">
          {check.test ? (
            <CheckCircle size="15" className="text-green-600" />
          ) : (
            <CircleX size="15" className="text-destructive" />
          )}
          <span className={`font-medium ${check.test ? "text-green-600" : ""}`}>
            {check.message}{" "}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PasswordStrengthCheck;
