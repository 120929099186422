import { FileInput, FileUploader } from "@/components/extension/file-upload";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useModal } from "@/dashboard/store/store";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import useImageCompressor from "@/hooks/useImageCompressor";
import usePostData from "@/hooks/usePostData";
import usePostMultipart from "@/hooks/usePostMultipart";
import { ProgramType } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import MapClusterPrograms from "./MapClusterPrograms";
import ProgramSummary from "./ProgramSummary";

const FormSchema = z.object({
  programPhoto: z.string().optional(),
  programName: z
    .string()
    .min(1, {
      message: "Le nom du programme est obligatoire.",
    })
    .max(120, { message: "120 caractères maximum" }),
  programDescription: z.string().optional(),
});

interface MyData {
  name: string;
  description: string;
}

const ProgramList = () => {
  const { data, loading, error } = useFetchDataV2(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );
  const [programs, setPrograms] = useState([]);
  const { handleCompressImage } = useImageCompressor();
  const { postData, loading: loadingSave, response } = usePostData<MyData>();
  const postMultipart = usePostMultipart();
  const [uploadPhotoLoading, setUploadPhotoLoading] = useState(false);
  const { setOpenModal, modals } = useModal();
  const [nameChar, setNameChar] = useState(0);
  const handlePhotoProgram = async (value) => {
    if (value) {
      setUploadPhotoLoading(true);

      // Compresser l'image avant l'upload
      const compressedBlob = await handleCompressImage(value[0], 500, 300, 0.9);

      // Si l'image est compressée, elle est disponible dans `compressedImageBlob`
      const data = new FormData();
      data.append("type", 8);
      if (compressedBlob) {
        for (let i = 0; i < value.length; i++) {
          data.append("files[]", compressedBlob);
        }
      } else {
        for (let i = 0; i < value.length; i++) {
          data.append("files[]", value[0]);
        }
      }
      const newMedias = await postMultipart.postMultipart({
        url: `${import.meta.env.VITE_IP_API}/api/media/temp/upload`,
        data,
      });
      if (
        !Array.isArray(newMedias) &&
        newMedias.success !== undefined &&
        newMedias.success !== null &&
        !newMedias.success
      ) {
        toast({
          title: newMedias.message,
        });
        setUploadPhotoLoading(false);
        return;
      }
      // await saveCover(newMedias[0]);
      setManagerLogo(newMedias[0]);
      setUploadPhotoLoading(false);
      return;
    }
  };
  const [managerLogo, setManagerLogo] = useState(response?.data?.name || null);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      programPhoto: "",
      programName: "",
      programDescription: "",
    },
  });
  async function onSubmit(formData: z.infer<typeof FormSchema>) {
    const data = {
      name: formData.programName,
      description: formData.programDescription || "",
      ...(managerLogo?.id && { cover: managerLogo.id }),
    };

    const result = await postData({
      url: `${import.meta.env.VITE_API_ENDPOINT}/program/save`,
      data,
    });

    form.reset();
    setManagerLogo(null);
    const newProgram = {
      id: result.id as number,
      name: result.name as string,
      description: result.description as string,
      cover: result.cover as string,
      createdAt: result.createdAt as Date,
      projects: result.projects as object,
      status: result.status as number,
    };
    setPrograms((prevPrograms) => [...prevPrograms, newProgram]);
    setOpenModal({ name: "program", value: false });
  }

  useEffect(() => {
    if (data) {
      setPrograms(data);
    }
  }, [data]);

  if (loading) return <LoadingSpinner />;
  if (error)
    return <p className="text-red-500">Une erreur est survenue : {error}</p>;
  if (!programs?.length)
    return <p className="text-gray-500">Aucun projet trouvé.</p>;
  return (
    <>
      <h1 className="mb-5 text-2xl font-medium">Liste de mes programmes</h1>
      <div className="flex flex-wrap">
        <div className="w-3/5">
          <div className="-mx-4 flex flex-wrap items-start gap-y-4">
            {programs?.map((program: ProgramType, key: number) => (
              <ProgramSummary
                className="w-full px-4 md:w-1/2 lg:w-1/3 "
                key={key}
                program={program}
              />
            ))}
            <div className="mb-4 w-full min-w-52 px-4 md:w-1/2 lg:w-1/3 ">
              <div className=" h-[175px] w-full " id="addProgram">
                <Dialog
                  open={modals["program"]}
                  onOpenChange={(value: boolean) =>
                    setOpenModal({ name: "program", value: value })
                  }
                >
                  <DialogTrigger asChild>
                    <div className="flex size-full flex-col items-start justify-center">
                      <Button
                        variant="outline"
                        className=" flex size-full items-center justify-center rounded-[15px] border"
                        style={{ background: "#EFEFEF" }}
                      >
                        <div className="flex w-max  items-center">
                          <svg
                            width="48"
                            height="36"
                            viewBox="0 0 48 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                              fill="#D4D4D4"
                            />
                            <rect
                              x="25"
                              y="19"
                              width="3"
                              height="11"
                              rx="1"
                              fill="#D9D9D9"
                            />
                            <rect
                              x="32"
                              y="23"
                              width="3"
                              height="11"
                              rx="1"
                              transform="rotate(90 32 23)"
                              fill="#D9D9D9"
                            />
                          </svg>
                        </div>
                      </Button>
                      <div className=" mt-4 flex cursor-pointer items-center gap-2 font-semibold text-foreground opacity-70">
                        Ajouter
                      </div>
                    </div>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[600px]">
                    <Form {...form}>
                      <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-6"
                      >
                        <DialogHeader>
                          <DialogTitle className="text-2xl">
                            Ajout de programme
                          </DialogTitle>
                          <DialogDescription>
                            Remplissez les informations ci-dessous pour ajouter
                            un nouveau programme à votre liste.
                          </DialogDescription>
                        </DialogHeader>

                        <div className="flex flex-wrap">
                          <div className="basis-1/3 pr-4">
                            <FormLabel className="mb-3 block">
                              Image du programme
                            </FormLabel>
                            <FileUploader
                              value={[]}
                              onValueChange={handlePhotoProgram}
                              dropzoneOptions={{
                                multiple: false,
                              }}
                              className={`relative h-full rounded-lg bg-background`}
                            >
                              <FileInput className="border-indigo-foreground size-full border-2 border-dashed">
                                {!uploadPhotoLoading &&
                                  managerLogo === null && (
                                    <div className="flex size-full flex-col items-center justify-center pb-4 pt-3">
                                      <svg
                                        className="size-8 text-gray-500 dark:text-gray-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 16"
                                      >
                                        <path
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                        />
                                      </svg>
                                      Photo
                                    </div>
                                  )}
                                {!uploadPhotoLoading &&
                                  managerLogo !== null && (
                                    <div className="flex size-full flex-col items-center justify-center">
                                      <img
                                        src={
                                          import.meta.env.VITE_MEDIA_BASE +
                                          managerLogo.uri
                                        }
                                        alt=""
                                        className="size-full object-cover"
                                      />
                                    </div>
                                  )}
                                {uploadPhotoLoading && (
                                  <div
                                    className={`w-35 relative mr-2 flex h-24 cursor-pointer items-center justify-center overflow-hidden rounded-md border border-input bg-gray-200`}
                                  >
                                    <div className="customfileuploaderloader">
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                      <div className="block"></div>
                                    </div>
                                  </div>
                                )}
                              </FileInput>
                            </FileUploader>
                          </div>
                          <div className="basis-2/3 pl-4">
                            <FormField
                              control={form.control}
                              name="programName"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>
                                    Nom du programme
                                    <span className="text-destructive">*</span>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      onKeyUp={(e) =>
                                        setNameChar(
                                          e.currentTarget.value.length
                                        )
                                      }
                                      placeholder="Nom du programme"
                                      {...field}
                                    />
                                  </FormControl>
                                  <div className="flex w-full">
                                    <div className="w-3/6">
                                      <FormMessage />
                                    </div>
                                    <FormDescription
                                      className={
                                        "w-3/6 text-right" +
                                        (nameChar > 120 ? " text-red-500" : "")
                                      }
                                    >
                                      {nameChar} / 120&nbsp;caractères
                                    </FormDescription>
                                  </div>
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name="programDescription"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>
                                    Description du programme
                                  </FormLabel>
                                  <FormControl>
                                    <Textarea
                                      placeholder="Description du programme"
                                      {...field}
                                      className="w-full"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                        <DialogFooter>
                          <Button type="submit" disabled={loadingSave}>
                            {loading ? "..." : "Sauvegarder"}
                          </Button>
                        </DialogFooter>
                      </form>
                    </Form>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 pl-6">
          <div className="overflow-hidden rounded-lg">
            <Suspense fallback={<LoadingSpinner />}>
              <MapClusterPrograms programs={programs} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramList;
