import { File, Folder, Tree } from "@/components/extension/tree-view-api";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { useFetchDataV2 } from "@/hooks/useFetchDataV2";
import { ProgramType } from "@/types";
import { FolderIcon } from "lucide-react";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

const TreeView = () => {
  const {
    data: programs,
    loading,
    error,
  } = useFetchDataV2<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );
  const { id } = useParams<{ id: string }>();
  const currentPage = useMemo(() => {
    const page = window.location.pathname;
    return page.includes("program")
      ? "program"
      : page.includes("project")
      ? "project"
      : "other";
  }, []);

  // Le variables elements sert a idéntifié quel élément on va ouvrir par défaut
  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: programs
        ?.map((item: ProgramType, key: number) => {
          return {
            id: (key + 10).toString(),
            isSelectable: true,
            name: item.name,
            children: item?.projects
              ?.map((project: { name: string }, k: number) => {
                return {
                  id: (k + 20).toString(),
                  isSelectable: true,
                  name: project.name,
                };
              })
              .filter(Boolean),
          };
        })
        .filter(Boolean),
    },
  ];

  // Calculer l'ID initial sélectionné
  const initialSelectedId = useMemo(() => {
    if (!id || !programs) return "1";
    const programIndex = programs.findIndex((p) => p.id == Number(id));
    return currentPage === "program"
      ? (programIndex + 10).toString()
      : currentPage === "project"
      ? (
          programs.findIndex(
            (p) => p.id == findProgramByProjectId(Number(id), programs)
          ) + 10
        ).toString()
      : "1";
  }, [id, programs, currentPage]);

  // Gestion du chargement et des erreurs
  if (loading) return <LoadingSpinner />;
  if (error) return <div>Une erreur est survenue: {error}</div>;

  return (
    <Tree
      initialSelectedId={initialSelectedId}
      elements={elements}
      className="mb-4 "
    >
      <Folder element="Mes programmes" value="1" visibleChevron={true}>
        <div className="flex flex-col gap-1">
          {programs?.map((item: ProgramType, key: number) => (
            <Folder
              element={item.name}
              value={(key + 10).toString()}
              key={key}
              visibleChevron={true}
              projectId={item.id}
            >
              {item.projects.map((project: any, k: number) => (
                <File
                  fileIcon=""
                  value={(k + 20).toString()}
                  key={k}
                  className="ml-4 w-full"
                >
                  <Link
                    to={`/dashboard/project/${project.id}`}
                    className={`group flex w-full gap-2 rounded-sm p-1  hover:text-[#1C5114] hover:opacity-100 ${
                      project.id == id ? "bg-[#1C5114]" : ""
                    }`}
                  >
                    <FolderIcon
                      className={`flex size-5 ${
                        project.id == id
                          ? "text-background"
                          : "text-[#878787] group-hover:text-[#1C5114]"
                      }`}
                    />
                    <div className="flex-1">
                      <span
                        title={project.name}
                        className={`flex-4 line-clamp-1 text-left text-[12px] hover:opacity-100 ${
                          project.id == id
                            ? "text-background"
                            : "text-[#878787] hover:text-[#1C5114]"
                        }`}
                      >
                        {project.name}
                      </span>
                    </div>
                  </Link>
                </File>
              ))}
            </Folder>
          ))}
        </div>
      </Folder>
    </Tree>
  );
};

// Fonction pour trouver le programme en fonction de l'ID du projet
function findProgramByProjectId(projectId: number, data: ProgramType[]) {
  const program = data.find((p: ProgramType) =>
    p.projects.some((project) => project.id === projectId)
  );
  return program ? program.id : null;
}
export default TreeView;
