import { usePercentageStore } from "@/dashboard/store/store";
import { FC, ReactNode, useState } from "react";
import HeaderTop from "./HeaderTop";
import Leftbar from "./Leftbar";

const LayoutDashboard: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { percentage } = usePercentageStore();
  return (
    <div className="relative flex flex-wrap">
      <div className={`${isOpen ? "w-[253px]" : "w-[69px]"} transition-all`}>
        <Leftbar setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
      <div className="flex-1 pl-10 pr-12">
        <HeaderTop />

        {children}
      </div>

      <div
        className="fixed left-0 top-0 z-[999999] h-1 w-full bg-green-600"
        style={{ width: percentage + "%" }}
      ></div>
    </div>
  );
};

export default LayoutDashboard;
