import { z } from "zod";

import { Button, buttonVariants } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { checkMailAndSendCode } from "../services/authService";

const ForgotPasswordForm = () => {
  const { loading } = useAuth();
  const [formIsLoading, setFormIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("auth", {
    keyPrefix: "login",
  });

  const formSchema = z.object({
    email: z.string().email({ message: t("Adresse email invalide") }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setFormIsLoading(true);
    const { success } = await checkMailAndSendCode({ email: values.email });
    const [localPart, domain] = values.email.split("@");
    const maskedLocalPart = localPart[0] + "***";
    const maskedDomain = domain[0] + "***";

    if (success) {
      // toast({
      //   title: "Nous vous enverrons un code à votre adresse e-mail",
      //   description: `Nous pouvons envoyer un code de connexion à : ${maskedLocalPart}@${maskedDomain}`,
      // });
      navigate("/reset-password?mail=" + values.email);
    }
    setFormIsLoading(false);
  }

  if (loading) return <LoadingSpinner />;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Mot de passe oublié ?")}</CardTitle>
        <CardDescription>
          {t(
            "Saisissez l'adresse e-mail associée à votre compte. Nous vous enverrons un code par e-mail pour réinitialiser votre mot de passe."
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Adresse e-mail")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("exemple@verdeo.fr")}
                      {...field}
                      type="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex gap-4">
              <Link
                to="/login"
                className={buttonVariants({ variant: "secondary" })}
              >
                {t("Annuler")}
              </Link>
              <Button
                type="submit"
                variant={"default"}
                className={`w-full ${
                  formIsLoading
                    ? "disabled pointer-events-none cursor-not-allowed"
                    : ""
                }`}
              >
                {formIsLoading ? <LoadingSpinner /> : t("Envoyer le code")}
              </Button>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ForgotPasswordForm;
