import useCheckIsDevice from "@/hooks/useDeviceType";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ProjectTitle = ({ project }) => {
  const isMobile = useCheckIsDevice("mobile");
  const [showDesc, setShowDesc] = useState(false);

  const { t } = useTranslation("submit", {
    keyPrefix: "project",
  });

  return (
    <div className="left-0 top-0 z-10 flex h-full flex-col justify-end px-[17px] pb-[20px] md:static md:w-3/4">
      <h1 className="mb-0 line-clamp-2 text-[24px] font-bold leading-8  text-[#626262]">
        {t("Projet")} : {project.name}
      </h1>
      {project.description && project.description !== "" && (
        <>
          <p
            className={`${
              !showDesc ? "line-clamp-3" : ""
            } overflow-hidden text-[14px] text-[#595959]`}
            dangerouslySetInnerHTML={{
              __html: project.description?.replace(/\n/g, "<br />"),
            }}
          />
          <div>
            <a
              href=""
              className="inline underline"
              onClick={(e) => {
                e.preventDefault();
                setShowDesc(!showDesc);
              }}
            >
              {showDesc ? t("Voir moins") : t("Voir plus")}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectTitle;
