import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ProjectSummary from "./ProjectSummary";

const ProjectList: FC<{ projects: any }> = ({ projects }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="-mx-4 mt-8 flex w-full flex-wrap items-start">
        {projects?.map((project: any, key: number) => (
          <div
            className="mb-4 min-w-52  px-4 sm:w-1/2 sm:max-w-[50%] md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] "
            key={key}
          >
            <ProjectSummary
              key={key}
              id={project.id}
              type={project.type}
              picture={
                project?.defaultImage
                  ? import.meta.env.VITE_MEDIA_BASE + project?.defaultImage?.uri
                  : null
              }
              title={project.name}
              link={`?project=${project.id}`}
              status={project.status}
              onSelect={() => navigate(`/dashboard/project/${project.id}`)}
              typeProjectImage=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
