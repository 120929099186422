import * as turf from "@turf/turf";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef } from "react";
import "./MapPrograms.css";

const MapClusterPrograms = ({ programs }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new maplibregl.Map({
        container: mapContainerRef.current,
        style:
          "https://tiles.stadiamaps.com/styles/alidade_smooth.json?api_key=" +
          import.meta.env.VITE_API_KEY_STADIAMAP,
        center: [2.842170943040401e-14, -5.625000000066052], // Initial focus coordinate
        zoom: 0,
      });

      const markerCollection = {
        type: "FeatureCollection",
        features: [],
      };

      // Ajouter des points dans markerCollection
      programs.forEach((program) => {
        program.projects.forEach((project) => {
          if (project.location && project.location.length > 0) {
            project.location.forEach((coordinates) => {
              const coordinate = coordinates.map((coord) => [
                coord[1],
                coord[0],
              ]);
              const features = turf.points(coordinate);
              const center = turf.center(features);
              markerCollection.features.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: center.geometry.coordinates,
                },
                properties: {
                  title: "program:" + program.name + "-" + project.name,
                },
              });
            });
          }

          if (project.parcel && project.parcel.length > 0) {
            project.parcel.forEach((coordinates) => {
              const features = turf.points(coordinates.coordinates);
              const center = turf.center(features);
              markerCollection.features.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: center.geometry.coordinates,
                },
                properties: {
                  title: "program:" + program.name + "-" + project.name,
                },
              });
            });
          }
        });
      });

      map.addControl(new maplibregl.NavigationControl());

      // Ajouter GeoJSON source et cluster layer
      map.on("load", async () => {
        // Load an image to use as the pattern
        const image = await map.loadImage("/pinco.png");
        // Declare the image
        map.addImage("pattern", image.data);
        map.setPaintProperty("water", "fill-color", "#75CFF0");
        map.setPaintProperty("background", "background-color", "#E7EBCC");
        map.setPaintProperty("building", "fill-color", "#E7EBCC");
        map.addSource("markers", {
          type: "geojson",
          data: markerCollection,
          cluster: true,
          clusterMaxZoom: 2, // Zoom maximum pour regrouper les points
          clusterRadius: 50, // Rayon en pixels pour le clustering
        });

        // Ajouter les clusters
        map.addLayer({
          id: "clusters",
          type: "symbol",
          source: "markers",
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "pattern",
            "icon-size": 0.8,
          },
        });

        // Nombre dans les clusters
        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "markers",
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "pattern",
            "icon-size": 0.8,
          },
        });

        // Points individuels
        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "markers",
          filter: ["!", ["has", "point_count"]],

          layout: {
            "icon-image": "pattern",
            "icon-size": 0.8,
          },
        });

        // Ajouter un popup pour les points non regroupés
        map.on("click", "unclustered-point", (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const title = e.features[0].properties.title;

          new maplibregl.Popup()
            .setLngLat(coordinates)
            .setHTML(`<div>${title}</div>`)
            .addTo(map);
        });

        // Zoomer sur le cluster au clic
        map.on("click", "clusters", (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties.cluster_id;
          map
            .getSource("markers")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });

        // Changer le curseur au survol
        map.on("mouseenter", "clusters", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "clusters", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      let isLocationLayerAdded = false; // Flag pour éviter d'ajouter la couche plusieurs fois
      map.on("zoom", () => {
        const zoomLevel = map.getZoom();

        if (zoomLevel >= 8) {
          // Ajout de la source et de la couche pour le zoom supérieur ou égal à 10
          if (!isLocationLayerAdded) {
            const features = programs.flatMap(
              (program) =>
                program.projects?.flatMap((project) => [
                  ...(project.location?.flatMap((item) =>
                    item?.length > 0 // Vérifie si item est défini et non vide
                      ? {
                          type: "Feature",
                          geometry: {
                            type: "Polygon",
                            coordinates: [item.map((i) => [i[1], i[0]])],
                          },
                        }
                      : null
                  ) ?? []),
                  ...(project.parcel?.flatMap((item) =>
                    item?.coordinates?.length > 0 // Vérifie si item est défini et non vide
                      ? {
                          type: "Feature",
                          geometry: {
                            type: "Polygon",
                            coordinates: [
                              item?.coordinates.map((i) => [i[0], i[1]]),
                            ],
                          },
                        }
                      : null
                  ) ?? []),
                ]) ?? [] // Évite de mapper sur undefined
            );
            if (features?.length > 0) {
              map.addSource("location", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: features,
                },
              });

              map.addLayer({
                id: "location",
                type: "fill",
                source: "location",
                layout: {},
                paint: {
                  "fill-color": "rgba(0, 166, 255, 0.25)",
                  "fill-opacity": 0.8,
                  "fill-outline-color": "#00A6FF",
                },
              });

              isLocationLayerAdded = true; // Mise à jour du flag
            } else {
              console.error("Aucune donnée valide pour ajouter des polygones.");
            }
          }
        } else {
          if (map.getLayer("location")) {
            map.removeLayer("location");
          }
          if (map.getSource("location")) {
            map.removeSource("location");
          }
          isLocationLayerAdded = false;
        }
      });
      return () => {
        map.remove();
      };
    }
  }, [programs]);

  return (
    <div
      ref={mapContainerRef}
      style={{ height: "70vh", width: "100%", zIndex: "1" }}
    />
  );
};

export default MapClusterPrograms;
