import { useAuth } from "@/auth/hooks/useAuth";
import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import ProjectBreadcrumb from "@/components/layout/ProjectBreadcrumb";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import useCheckIsDevice from "@/hooks/useDeviceType";
import {
  convertStringToNumber,
  reverseDataProcessingProject,
} from "@/libs/utils";
import StepRenderer from "@/programs/components/steps/StepRenderer";
import Treeview from "@/programs/components/Treeview";
import useProgram from "@/programs/hooks/useProgram";
import { fetchProgram } from "@/programs/services/programService";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import slugify from "slugify";

const Program: FC = () => {
  const navigate = useNavigate();

  const code = localStorage.getItem("code");

  if (code !== import.meta.env.VITE_API_CODE) {
    navigate(`/`);
  }

  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const { programData, currentId, updateProgramData } = useProgram();
  const [loadData, setLoadData] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project") || "";
  const isMobile = useCheckIsDevice("mobile");
  const program = programData[idParams as number];

  const [uploadLogoLoading, setUploadLogoLoading] = useState(false);
  const [picture, setPicture] = useState({
    uri: "",
    alt: "",
  });

  const { t } = useTranslation("submit", {
    keyPrefix: "program",
  });

  const DefaultbreadcrumbItems = [
    {
      href: "/submit/programs",
      name: t("Mes programmes"),
    },
    // Vérification si programName n'est pas undefined avant de l'ajouter
    ...(programData[idParams]?.programName
      ? [
          {
            name: programData[idParams].programName,
          },
        ]
      : []),
  ];
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { name: string; href?: string }[]
  >(DefaultbreadcrumbItems);

  useEffect(() => {
    handleChangeStep(1);
    if (currentId === null) {
      return;
    }

    fetchProgram(idParams as number).then(async (data) => {
      if (data && data.projects !== undefined) {
        const slugs = [];
        let projects = [];
        for (let i = 0; i < data.projects.length; i++) {
          const project = data.projects[i];
          const slug = slugify(project.type.name, {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
          });
          slugs.push(slug);
          projects = [...projects, reverseDataProcessingProject(project)];
        }

        updateProgramData("typesProject", slugs);

        updateProgramData("projects", projects.length ? [...projects] : [{}]);

        updateProgramData("programName", data.name);
        updateProgramData("programDescription", data.description);
        updateProgramData("programId", data.id);
      }
    });
  }, [currentId]);

  useEffect(() => {
    if (
      programData[idParams] !== undefined &&
      programData[idParams].projects.length
    ) {
      setBreadcrumbItems([
        {
          href: "/submit/programs",
          name: t("Mes programmes"),
        },
        // Vérification si programName n'est pas undefined avant de l'ajouter
        ...(programData[idParams]?.programName
          ? [
              {
                name: programData[idParams].programName,
              },
            ]
          : []),
      ]);
      setLoadData(false);
    } else {
      setLoadData(true);
    }
  }, [programData]);

  /**
   * Changelog [FIX] (Hoby) On rédirige l'utilisateur s'il ne met pas d'id dans l'url ou l'id n'est pas nombre
   */
  useEffect(() => {
    if (!idParams) {
      navigate("/submit/programs");
    }
  }, [idParams, navigate]);

  useEffect(() => {
    if (
      programData[idParams as number] !== undefined &&
      programData[idParams as number]?.projects?.length
    ) {
      setLoadData(false);
    } else {
      window.scrollTo(0, 0);
      setLoadData(true);
    }
  }, [programData]);

  const [currentStep, setCurrentStep] = useState<number>(() => {
    const stepLocalStorage = localStorage.getItem("step");
    return stepLocalStorage ? JSON.parse(stepLocalStorage) : 1;
  });

  useEffect(() => {
    if (currentStep > 1) {
      const project =
        programData[idParams]?.projects?.find((p) => p.id == projectId) || {};
      setPicture({
        uri: project?.defaultImage?.uri || "",
        alt: project?.defaultImage?.alt || "",
      });
      setUploadLogoLoading(false);

      setBreadcrumbItems([
        {
          href: "/submit/programs",
          name: t("Mes programmes"),
        },
        {
          name: programData[idParams]?.programName || "",
          href: `/submit/program/${idParams}`,
        },
        {
          name:
            programData[idParams]?.projects.find((p) => p.id == projectId)
              ?.name || "",
        },
      ]);
    }
  }, [currentStep, programData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
    localStorage.setItem("step", JSON.stringify(step));
  };

  useEffect(() => {
    if (projectId !== "" && (currentStep === undefined || currentStep === 1)) {
      handleChangeStep(2);
    }
  });

  const { isAuthenticated, loading, user } = useAuth();
  //Si l'utilisation n'est déjà connecté, on le redirige vers la page login
  if ((!isAuthenticated || !user?.isVerified) && !loading) {
    navigate(`/login`);
    return <></>;
  }

  if (loading) {
    return (
      <div className="container min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="container mb-0">
        <ReactBreadcrumb items={breadcrumbItems} />
      </div>
      <div className="container">
        {/* {currentStep != 1 && <HeaderProjectDescription idParams={idParams} />} */}
        <div className="flex w-full flex-wrap items-start">
          {!isMobile && (
            <div className="relative basis-full pr-4 md:basis-1/4 md:pt-4">
              {currentId && (
                <>
                  {currentStep > 1 && (
                    <ProjectBreadcrumb
                      project={
                        programData[idParams]?.projects?.find(
                          (p) => p.id == projectId
                        ) || {}
                      }
                    />
                  )}

                  <Treeview
                    handleChangeStep={handleChangeStep}
                    currentStep={currentStep}
                  />
                </>
              )}
            </div>
          )}
          {!loadData && (
            <div className={`w-full flex-1 py-4 md:px-4`}>
              <StepRenderer
                currentStep={currentStep}
                onCurrentChange={handleChangeStep}
              />
            </div>
          )}
          {loadData && <LoadingSpinner />}
        </div>
      </div>
    </>
  );
};

export default Program;
