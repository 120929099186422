import useImageCompressor from "@/hooks/useImageCompressor";
import usePostMultipart from "@/hooks/usePostMultipart";
import { convertStringToNumber } from "@/libs/utils";
import { useDatabase } from "@/programs/hooks/useDatabase";
import useProgram from "@/programs/hooks/useProgram";
import { Edit } from "lucide-react";
import { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FileInput, FileUploader } from "../extension/file-upload";
import { toast } from "../ui/use-toast";

const EditPhotoHeader: FC<{
  setPicture: ({ uri, alt }: { uri: string; alt: string }) => void;
  picture: { uri: string; alt: string };
  uploadLogoLoading: boolean;
  setUploadLogoLoading: (value: boolean) => void;
}> = ({ setPicture, picture, uploadLogoLoading, setUploadLogoLoading }) => {
  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const { handleCompressImage } = useImageCompressor();

  const { programData, updateProgramData } = useProgram();
  const program = programData[idParams as number];
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("project") || "";
  const project =
    program?.projects.find((p) => p.id === convertStringToNumber(projectId)) ||
    {};

  const { postMultipart } = usePostMultipart();
  const { updateProjectInDB } = useDatabase();
  const handlePictureChange = async (value) => {
    if (value) {
      setUploadLogoLoading(true);
      const data = new FormData();

      data.append("type", 5);

      // Compresser l'image avant l'upload
      const compressedBlob = await handleCompressImage(value[0], 500, 300, 0.9);
      if (compressedBlob) {
        for (let i = 0; i < value.length; i++) {
          data.append("files[]", compressedBlob);
        }
      } else {
        for (let i = 0; i < value.length; i++) {
          data.append("files[]", value[i]);
        }
      }

      const newMedias = await postMultipart({
        url: `${import.meta.env.VITE_IP_API}/api/media/temp/upload`,
        data,
      });
      if (
        !Array.isArray(newMedias) &&
        newMedias.success !== undefined &&
        newMedias.success !== null &&
        !newMedias.success
      ) {
        toast({
          title: newMedias.message,
        });
        setUploadLogoLoading(false);
        return;
      }

      // setPicture(newMedias[0]);

      await handleSubmit(newMedias[0]);
      // setUploadLogoLoading(false);
      return;
    }
  };
  async function handleSubmit(media) {
    await updateProjectInDB(
      {
        cover: media.id,
      },
      project.id
    );

    const updatedProjects = program?.projects?.map((p, i) => {
      if (p.id == projectId) {
        return {
          ...p,
          defaultImage: media,
        };
      }
      return p;
    });

    updateProgramData("projects", updatedProjects);
  }
  return (
    <FileUploader
      value={[]}
      onValueChange={handlePictureChange}
      dropzoneOptions={{
        multiple: false,
      }}
      className={`relative size-full `}
    >
      <FileInput className="size-full rounded-[20px] border border-dashed border-background/50">
        {/* {!uploadLogoLoading && picture.uri === "" && ( */}
        <div className="absolute right-4 top-4 flex size-8 flex-col items-center justify-center bg-background ">
          <Edit />
        </div>
        {/* )} */}

        {uploadLogoLoading && (
          <div
            className={`w-35 relative flex h-full cursor-pointer items-center justify-center overflow-hidden border  border-input bg-[#f4f4f4]`}
          >
            <div className="customfileuploaderloader">
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
              <div className="block"></div>
            </div>
          </div>
        )}
      </FileInput>
    </FileUploader>
  );
};

export default EditPhotoHeader;
