import ProjectTitle from "@/components/layout/ProjectTitle";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { convertStringToNumber } from "@/libs/utils";
import useProgram from "@/programs/hooks/useProgram";
import { FC, lazy, Suspense, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// Lazy loading des composants Step
const Step1 = lazy(() => import("@/programs/components/steps/Step1"));
const Step2 = lazy(() => import("@/programs/components/steps/Step2"));
const Step3 = lazy(() => import("@/programs/components/steps/Step3"));
const Step4 = lazy(() => import("@/programs/components/steps/Step4"));
const Step5 = lazy(() => import("@/programs/components/steps/Step5"));
const Step6 = lazy(() => import("@/programs/components/steps/Step6"));
const Step7 = lazy(() => import("@/programs/components/steps/Step7"));
const Step8 = lazy(() => import("@/programs/components/steps/Step8"));
const Step9 = lazy(() => import("@/programs/components/steps/Step9"));

const StepRenderer: FC<{
  currentStep: number;
  onCurrentChange: (step: number) => void;
}> = ({ currentStep, onCurrentChange }) => {
  const stepRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const projectId = searchParams.get("project") || "";
  const { programData } = useProgram();

  useEffect(() => {
    const stepParam = searchParams.get("step");
    const step = stepParam ? Number(stepParam) : NaN;
    const isStepNumber = !isNaN(step) && Number.isInteger(step);

    if (isStepNumber) {
      onCurrentChange(step);
      searchParams.delete("step");
      setSearchParams(searchParams); // Met à jour l'URL sans le paramètre
    }
  }, [searchParams, onCurrentChange, setSearchParams]);

  return (
    <>
    {currentStep > 1 && <ProjectTitle project={programData[idParams]?.projects?.find((p) => p.id == projectId) || {}} />}
    <div ref={stepRef} className={currentStep != 1 ? 'rounded-[20px] border px-6 pb-6' : ''}>
      <Suspense fallback={<LoadingSpinner />}>
        {currentStep === 1 && <Step1 onCurrentChange={onCurrentChange} />}
        {currentStep === 2 && <Step2 onCurrentChange={onCurrentChange} />}
        {currentStep === 3 && <Step3 onCurrentChange={onCurrentChange} />}
        {currentStep === 4 && <Step4 onCurrentChange={onCurrentChange} />}
        {currentStep === 5 && <Step5 onCurrentChange={onCurrentChange} />}
        {currentStep === 6 && <Step6 onCurrentChange={onCurrentChange} />}
        {currentStep === 7 && <Step7 onCurrentChange={onCurrentChange} />}
        {currentStep === 8 && <Step8 onCurrentChange={onCurrentChange} />}
        {currentStep === 9 && <Step9 onCurrentChange={onCurrentChange} />}
      </Suspense>
    </div>
    </>
  );
};

export default StepRenderer;
